<template>
  <el-dialog
    :visible.sync="dialogVisible"
    custom-class="detail_dialog"
    :top="'2vh'"
    :fullscreen="isExpand"
    :show-close="false"
    :before-close="handleClose"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <template slot="title">
      <div class="title-box">
        <span>设备签收</span>
        <span class="icon-box">
          <img :src="!isExpand ? expandSvg : collapseSvg" @click="isExpand = !isExpand" />
          <i class="el-icon-close" @click="handleClose"></i>
        </span>
      </div>
    </template>
    <el-form ref="form" :model="form" :rules="rules">
      <el-row>
        <el-col :span="24">
          <el-form-item label="签收设备图片" prop="deviceAnnexList" label-width="8em">
            <Upload
              v-model="form.deviceAnnexList"
              accept="image/png,image/jpeg,image/jpg,image/bmp"
              @remove="v => handleRemove(v, 'deviceAnnexList')"
              :maxSize="10"
              size="origin"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="签收包装图片" prop="wrapAnnexList" label-width="8em">
            <Upload
              v-model="form.wrapAnnexList"
              accept="image/png,image/jpeg,image/jpg,image/bmp"
              @remove="v => handleRemove(v, 'wrapAnnexList')"
              :maxSize="10"
              size="origin"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="外包装情况" prop="wrapFlag" label-width="8em">
            <el-radio-group v-model="form.wrapFlag">
              <el-radio :label="1">完好</el-radio>
              <el-radio :label="2">轻微破损</el-radio>
              <el-radio :label="3">严重破损</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="设备外观" prop="facadeFlag" label-width="8em">
            <el-radio-group v-model="form.facadeFlag">
              <el-radio :label="1">完好</el-radio>
              <el-radio :label="2">轻微破损</el-radio>
              <el-radio :label="3">严重破损</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="签收备注" prop="remark" label-width="8em">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="3"
              placeholder="请输入签收备注"
              :maxlength="200"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" plain @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSave">确定签收</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { sendRepairGoodsSendoutSign, sendRepairSpareSendBackSign } from '@/api/logistics.js';

export default {
  props: {
    type: {
      type: Number,
      default: 1, // 1 -> 寄修， 2 -> 备用机
    },
  },
  data () {
    return {
      dialogVisible: false,
      isExpand: false,
      expandSvg: require('@/assets/ic_expand.svg'),
      collapseSvg: require('@/assets/ic_collapse.svg'),
      form: {
        id: undefined,
        deviceAnnexList: [],
        wrapAnnexList: [],
        facadeFlag: [],
        wrapFlag: [],
        remark: '',
      },
      rules: {
        deviceAnnexList: [{ required: true, message: '请选择签收设备图片', trigger: 'change' }],
        wrapAnnexList: [{ required: true, message: '请选择签收包装图片', trigger: 'change' }],
        facadeFlag: [{ required: true, message: '请选择外包装情况', trigger: 'change' }],
        wrapFlag: [{ required: true, message: '请选择设备外观', trigger: 'change' }],
      },
    };
  },
  methods: {
    init (id) {
      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.form = this.$options.data().form;
        this.form.id = id;
      });
    },
    handleClose () {
      this.dialogVisible = false;
    },
    handleSave () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const api = this.type === 1 ? sendRepairGoodsSendoutSign : sendRepairSpareSendBackSign;

          await api({
            ...this.form,
            deviceAnnexList: this.form.deviceAnnexList.map(v => ({
              annexName: v.attachName,
              url: v.url,
            })),
            wrapAnnexList: this.form.wrapAnnexList.map(v => ({
              annexName: v.attachName,
              url: v.url,
            })),
          });

          this.$message.success('签收成功');

          this.handleClose();
          this.$emit('callback');
        }
      });
    },
    handleRemove (file, key) {
      const index = this.form[key].findIndex(v => v.url === file.url);
      this.form[key].splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.detail_dialog {
  /deep/ .el-dialog__header {
    padding: 16px 20px;
  }

  .title-box {
    color: #303133;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-box {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;

        &:hover {
          cursor: pointer;
        }
      }

      i {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
