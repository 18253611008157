import server from '../request/encryptRequest.js';

// 查询分页列表
export const sendRepairPageList = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/pageList', params);

// 查询详情
export const sendRepairDetail = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/detail', params);

// 状态统计
export const sendRepairStatusStatistics = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/statusStatistics', params);

// 设备寄出签收
export const sendRepairGoodsSendoutSign = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/send/out/sign', params);

// 设备寄回
export const sendRepairGoodsSendBack = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/send/back', params);

export const dynamicInfoPageList = (params) => server.post('/xiaozi-xmb/wireless/saas/dynamicInfo/pageList', params);

// 备用机关闭
export const sendRepairSpareClose = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/spare/close', params);

// 备用机寄回签收
export const sendRepairSpareSendBackSign = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/spare/send/back/sign', params);

// 新增备用机寄出
export const sendRepairSpareSendOut = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/spare/send/out', params);

// 推送到对接系统
export const pushToDockingSystem = (params) => server.post('/xiaozi-xmb/wireless/saas/send/repair/pushToDockingSystem', params);

