<template>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true" custom-class="detail_dialog" :top="'2vh'" :fullscreen="isExpand"
        :show-close="false" :destroy-on-close="true">
        <template slot="title">
            <div class="title-box">
                <span>快递详情</span>
                <span class="icon-box">
                    <img :src="!isExpand ? expandSvg : collapseSvg" @click="isExpand = !isExpand">
                    <i class="el-icon-close" @click="dialogVisible = false"></i>
                </span>
            </div>
        </template>
        <div class="content-wrapper">
          <div class="express-info">
            <div class="express">
              <span class="name">{{ courierName }}</span>
              <span class="code">{{ courierNumber }}</span>
              <img :src="require('@/assets/icon-copy.svg')" @click="handleCopy" />
            </div>
          </div>
          <div class="steps-wrapper">
            <div v-if="logisticTracks.length" class="steps" :style="{height: stepHeight + 'px'}">
              <el-timeline>
                <el-timeline-item v-for="(step, index) in logisticTracks" :key="index">
                  <div v-if="index === 0" slot="dot" class="circle">
                    <div class="solid-circle"></div>
                  </div>
                  <div :class="[index === 0 ? 'node-active' : 'little-node']">{{ step.status }}</div>
                  <div :class="[index === 0 ? '' : 'little-node']">{{ step.ftime }}</div>
                  <div :class="[index === 0 ? '' : 'little-node']">{{ step.context }}</div>
                </el-timeline-item>
              </el-timeline>
            </div>
            <el-empty v-else description="暂无物流信息" :image="require('@/assets/empty.png')"></el-empty>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>
<script>
import * as Moment from 'dayjs';

export default {
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
  },
  data () {
    return {
      isExpand: false,
      dialogVisible: false,
      expandSvg: require('@/assets/ic_expand.svg'),
      collapseSvg: require('@/assets/ic_collapse.svg'),
      courierName: '',
      courierNumber: '',
      logisticTracks: [],
      stepHeight: 0,
    };
  },
  methods: {
    init (courierName, courierNumber, logisticTracks) {
      this.courierName = courierName;
      this.courierNumber = courierNumber;
      this.logisticTracks = logisticTracks;

      this.stepHeight = logisticTracks?.length ? (logisticTracks.length * 80) : 300;

      this.dialogVisible = true;
    },
    formatDate (text) {
      return text ? Moment(text).format('YYYY-MM-DD') : '';
    },
    async handleCopy () {
      try {
        await navigator.clipboard.writeText(this.courierNumber);
        this.$message({
          type: 'success',
          message: '文本已成功拷贝到剪贴板',
          duration: 1000,
        });
      } catch (err) {
        console.error('无法拷贝文本: ', err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .detail_dialog {
    /deep/ .el-dialog__header {
      padding: 16px 20px;
    }

    .title-box {
      color: #303133;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon-box {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;

          &:hover {
            cursor: pointer;
          }
        }

        i {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .content-wrapper {
      padding-left: 20px;
      padding-right: 20px;

      .express-info {
        display: flex;
        align-items: center;
        height: 34px;
        border-bottom: 1px solid rgb(238, 238, 238);

        .express {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #1F2733;

          .code {
            margin-left: 16px;
            margin-right: 10px;
          }

          img {
            cursor: copy;
          }
        }

        .client {
          position: relative;
          flex: 1;
          padding-left: 75px;

          &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 60px;
            width: 1px;
            height: 100%;
            background-color: #DFE2E8;;
          }

          .send {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #FEF0E5;
            border-radius: 50%;
            margin-bottom: 8px;
          }

          .receive {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #E2F3FE;
            border-radius: 50%;
          }
        }
      }

      .steps-wrapper {
        max-height: 500px;
        overflow-y: auto;

        .steps {
          padding: 16px;
          min-height: 300px;

          /deep/.el-timeline-item:first-of-type {
            .el-timeline-item__dot {
              left: -5px;

              .circle {
                position: relative;
                width: 20px;
                height: 20px;
                border: 1px solid #237FFA;
                border-radius: 50%;
                background-color: #fff;

                .solid-circle {
                  position: absolute;
                  left: 3px;
                  top: 3px;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background-color: #237FFA;
                }
              }
            }
          }

          .node-active {
              font-size: 16px;
              color: #237FFA;
          }

          .little-node {
            color: #929AA6;
          }
        }
      }
    }
  }
</style>
