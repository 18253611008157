<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <div class="table-wrapper">
          <xz-table
            ref="xzTable"
            :columns="columnsLists"
            :data="data"
            :scroll="{ x: '100%', y: scrollHeight }"
            :drawerWidth="500"
            rowKey="id"
          >
            <span slot="statusMsg" slot-scope="_, record">
              <span v-if="record.statusMsg" :class="['c_tag', record.status ? `status-${record.status}` : '']">
                {{ record.statusMsg }}
              </span>
              <el-tooltip
                v-if="record.status === 8"
                effect="dark"
                :content="`关闭原因：${record.closeReason}`"
                placement="top-start"
              >
                <i :class="['el-icon-warning-outline', record.status ? `status-${record.status}` : '']"></i>
              </el-tooltip>
            </span>
            <span slot="code" slot-scope="text, record">
              <span :class="[sendRepairDetailPermission ? 'code' : '']" @click="() => handleView(record)">{{ text }}</span>
            </span>
            <div slot="action" slot-scope="_, record">
              <a v-if="sendRepairDetailPermission" style="margin-right: 8px" @click="() => handleAction(record, 'view')">查看</a>
              <a v-if="sendRepairSendBackPermission && record.status === 3 && record.checkStatus === 0" @click="() => handleAction(record, 'sendBack')">
                寄回
              </a>
              <a v-if="sendRepairSendOutSignPermission && record.status === 2 && record.checkStatus === 0" @click="() => handleAction(record, 'signFor')">
                签收
              </a>
            </div>
          </xz-table>
        </div>
      </div>
    </div>
    <div v-if="total > 10" class="pagination-wrapper">
      <a-pagination
        :current="page.pageNum"
        :total="total"
        :show-total="total => `共 ${total} 条`"
        show-quick-jumper
        @change="handleNumChange"
      />
    </div>
    <DetailDialog
      ref="DetailDialogRef"
      v-if="dialogVisible"
      :dialog-visible.sync="dialogVisible"
      :id="currentRow.id"
    ></DetailDialog>
    <SignForDialog ref="signForDialogRef" @callback="handleCallback"></SignForDialog>
    <SendbackDialog ref="sendbackDialogRef" @callback="handleCallback"></SendbackDialog>
  </div>
</template>

<script>
import { sendRepairPageList } from '@/api/logistics.js';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import DetailDialog from '@/views/Logistics/sendForRepair/components/detailDialog.vue';
import SignForDialog from '@/views/Logistics/components/signForDialog.vue';
import SendbackDialog from '@/views/Logistics/components/sendbackDialog.vue';
import mixin from '../../../mixin';

const COMMON_COLUMNS = [
  {
    title: '寄修单号',
    dataIndex: 'code',
    key: 'code',
    width: 160,
    ellipsis: true,
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '寄修状态',
    dataIndex: 'statusMsg',
    key: 'statusMsg',
    width: 140,
    ellipsis: true,
    scopedSlots: { customRender: 'statusMsg' },
  },
  {
    title: '验收状态',
    dataIndex: 'checkStatusMsg',
    key: 'checkStatusMsg',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '用户名称',
    dataIndex: 'userParty',
    key: 'userParty',
    width: 160,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '创建人',
    dataIndex: 'createUserName',
    key: 'createUserName',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '联系方式',
    dataIndex: 'createUserPhone',
    key: 'createUserPhone',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
];

export default {
  name: 'LVYUE_SEND_FOR_REPAIR',
  mixins: [mixin],
  props: {
    inquirePriceCode: {
      type: String,
      required: true,
    },
    isHandle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    xzTable,
    DetailDialog,
    SignForDialog,
    SendbackDialog,
  },
  computed: {
    columnsLists () {
      if (this.isHandle) {
        return [
          ...COMMON_COLUMNS,
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 100,
            scopedSlots: { customRender: 'action' },
          },
        ];
      } else {
        return COMMON_COLUMNS;
      }
    },
  },
  watch: {
    inquirePriceCode: {
      handler (v) {
        this.form.inquirePriceCode = v;

        if (this.form.inquirePriceCode) {
          this.handleSearch();
        }
      },
      immediate: true,
    },
  },
  inject: {
    closeDialog: {
      default: undefined,
    },
  },
  data () {
    return {
      dialogVisible: false,
      data: [],
      form: {
        inquirePriceCode: '',
      },
      total: 0,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      scrollHeight: 420,
      page: {
        pageSize: 10,
        pageNum: 1,
      },
    };
  },
  methods: {
    handleView (record) {
      if (this.sendRepairDetailPermission) {
        if (this.isHandle) {
          this.currentRow = record;
          this.dialogVisible = true;
        } else {
          this.closeDialog && this.closeDialog();

          this.$router.push({
            name: 'LOGISTICS_SEND_FOR_REPAIR_DETAIL',
            query: {
              id: record.id,
            },
          });
        }
      }
    },
    async handleSearch () {
      this.data = [];
      const params = {
        type: '1', // 寄修单
        ...this.form,
        pageFlag: false,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };

      const { body } = await sendRepairPageList(params);

      this.data = body?.list || [];

      this.total = (body?.total || 0) * 1;
    },
    handleAction (record, action) {
      if (action === 'sendBack') {
        this.$refs.sendbackDialogRef.expressOptions = this.expressOptions;
        this.$refs.sendbackDialogRef.init(record.id);
      } else if (action === 'signFor') {
        this.$refs.signForDialogRef.init(record.id);
      } else if (action === 'view') {
        this.$router.push({
          name: 'LOGISTICS_SEND_FOR_REPAIR_DETAIL',
          query: {
            id: record.id,
          },
        });
      }
    },
    handleCallback () {
      this.handleSearch();
    },
    handleNumChange (num) {
      this.page.pageNum = num;

      this.handleSearch();
    },
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 8px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .table-wrapper {
        padding: 16px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper {
          border-width: 0;
          background-color: #f2f4f7;

          &.active-radio {
            border-color: #e2f3fe;
            background-color: #e2f3fe;
            color: #237ffa;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .c_tag {
          @include tag_round();
          margin-right: 0.2em;
        }

        .status-2,
        .status-3 {
          background-color: #fefae0;
          color: #fab007;

          &:before {
            color: #fab007;
          }
        }

        .status-4,
        .status-1 {
          background: #e2f3fe;
          color: #237ffa;

          &:before {
            color: #237ffa;
          }
        }

        .status-5 {
          background: #E5FEEB;
          color: #14CA64;

          &:before {
            color: #14CA64;
          }
        }

        .status-8 {
          background-color: #f2f4f7;
          color: #1f2733;

          &:before {
            color: #1f2733;
          }
        }

        .code {
          color: #237ffa;
          cursor: pointer;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.text-right {
  text-align: right;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
  margin-top: 6px;
}

.top {
  display: flex;
  padding: 6px 0;
  background: #ffffff;
  box-shadow: inset 0 -1px 0 0 #dfe2e8;
  margin-bottom: 10px;
  font-family: PingFang SC;
  font-size: 16px;
  color: #1f2733 100%;
  letter-spacing: 0px;
  opacity: 1;

  span {
    flex: 1;
    height: 24px;
    line-height: 24px;
  }

  time {
    cursor: pointer;
  }
}

.list-item {
  display: flex;
  cursor: move;
  height: 40px;
  padding: 4px 8px;

  .el-checkbox__label {
    height: 32px;
    line-height: 32px;
  }

  .labelContainer {
    flex: 1;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
  }
}

.noFixedItem {
  font-family: PingFang SC;
  font-size: 14px;
  color: #929aa6;
  letter-spacing: 0px;
  line-height: 0px;
  opacity: 1;
  height: 40px;
  line-height: 32px;
  padding: 4px 8px;
  list-style: none;
}

/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background: rgb(35, 127, 250);
  border-color: rgb(35, 127, 250);
  opacity: 0.4;
  color: #ffffff;
}

/deep/.ant-table-body::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

/deep/ .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px #c9ced6;
  background: #c9ced6;
}

//鼠标移入样式
/deep/ .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #c9ced6;
}

.advanced-search {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

/deep/.ant-drawer-body {
  padding: 16px;
}
</style>
