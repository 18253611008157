
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['authAllMap']),
    sendRepairListPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairList'];
    },
    sendRepairDetailPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairDetail'];
    },
    sendRepairSendOutSignPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairSendOutSign'];
    },
    sendRepairSendBackPermission () {
      return true || this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairSendBack'];
    },
    sendRepairSpareListPermission () {
      return true || this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairSpareList'];
    },
    sendRepairSpareDetailPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairSpareDetail'];
    },
    sendRepairSpareAddPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairSpareAdd'];
    },
    sendRepairSpareClosePermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairSpareClose'];
    },
    sendRepairSpareSendBackSignPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:sendRepairSpareSendBackSign'];
    },
    workOrderListPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:workOrderList'];
    },
    workOrderDetailPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:workOrderDetail'];
    },
    workOrderPrintPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:customer_inquire:workOrderPrint'];
    },
    lvyuePermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue'];
    },
  },
};
