<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <div class="c_group">
          <XzTagGroup :data="statusWithAll" :active="form.fullFlag"
            @handleClick="(v) => handleStatusChange('fullFlag', v)">
            <span class="title">商机状态</span>
          </XzTagGroup>
          <div class="c_query">
            <span>排序：
              <span class="c_time">
                <span>发布时间</span>
                <i :class="['sort-caret', 'ascending', sortFlag === 'ASC' ? 'active' : '']"
                  @click="() => handleSort('ASC')"></i>
                <i :class="['sort-caret', 'descending', sortFlag === 'DESC' ? 'active' : '']"
                  @click="() => handleSort('DESC')"></i>
              </span>
            </span>
            <el-input placeholder="请输入交付地址" v-model="form.deliveryProvinceNameOrCityName" clearable>
              <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
            </el-input>
          </div>
        </div>
        <div class="table-wrapper">
          <div class="card-wrapper" v-for="v in tableData" :key="v.id" @click="() => handleView(v)">
            <div class="inner_card">
              <div :class="['card_title', v.emergencyLevel === 'HIGH' ? 'urgent' : '']">
                <div>
                  <div class="title_text">{{ v.releaseTenantIsPersonal ? `个人询单【***】` : v.inquirePriceParty }}<el-image v-if="v.zgyFlag" style="margin-left: 8px;"
                      :src="v1Ziguanyi"></el-image>
                  </div>
                  <div class="release_time">发布时间：{{ formatTime(v.releaseTime) }}</div>
                </div>
                <el-image v-if="v.emergencyLevel === 'HIGH'" :src="jidan"></el-image>
                <el-image v-if="v.scrambleFlag" :src="qiangdanchenggong"></el-image>
              </div>
              <div class="line"></div>
              <div :class="['content1', !v.remainingScrambleNum && !v.scrambleFlag ? 'forbid' : '']">{{ v.title }}</div>
              <div :class="['content2', !v.remainingScrambleNum && !v.scrambleFlag ? 'forbid' : '']">
                <div>
                  <el-image
                    v-if="v.businessType === 'MAINTAIN' && v.deviceFaultImageList && v.deviceFaultImageList[0] && v.deviceFaultImageList[0].url"
                    style="width: 80px; height: 80px; border-radius: 4px;" fit="scale-down"
                    :src="v.deviceFaultImageList[0].url"></el-image>
                  <el-image
                    v-else-if="v.businessType !== 'MAINTAIN' && v.sparePartImageList && v.sparePartImageList[0] && v.sparePartImageList[0].url"
                    style="width: 80px; height: 80px; border-radius: 4px;" fit="scale-down"
                    :src="v.sparePartImageList[0].url"></el-image>
                  <el-image v-else :src="noImage"
                    style="width: 80px; height: 80px; background: #F5F7FA; padding: 20px; border-radius: 4px;"></el-image>
                </div>
                <div class="content2-text">
                  {{ v.subTitle }}
                </div>
              </div>
              <div :class="['content3', !v.remainingScrambleNum && !v.scrambleFlag ? 'forbid' : '']">
                <div style="flex: 1;">
                  <div class="content3-text"><el-image :src="clock"></el-image>
                    <div class="text" :title="formatTime(v.expectedDeliveryDate)">期望交期：{{
                      formatTime(v.expectedDeliveryDate) }}</div>
                  </div>
                  <div class="content3-text"><el-image :src="location"></el-image>
                    <div class="text">交付地址：{{
                      `${v.deliveryProvinceName}${v.deliveryCityName}` }}</div>
                  </div>
                </div>
                <div>
                  <div class="content3-text--sup">{{ v.participationMerchantNum }}人参与，剩{{ v.remainingScrambleNum }}个名额
                  </div>
                  <el-button
                    :disabled="v.inquireTenantStatus === 'REJECTED' || (v.scrambleFlag && v.inquireTenantStatus === 'QUOTED') || (!v.remainingScrambleNum && !v.scrambleFlag)"
                    style="width: 100%; height: 32px;" type="primary" size="mini" @click.stop="() => handlePress(v)">
                    <div style="display: flex; align-items: center; justify-content: center;"><el-image
                        :src="v.scrambleFlag ? baojia : !v.remainingScrambleNum ? yimane : qiangdan"></el-image>{{
                          v.scrambleFlag ? '报价' : !v.remainingScrambleNum ? '已满额' : '抢单' }}</div>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper">
      <a-pagination :current="page.pageNum" :total="total" :show-total="total => `共 ${total} 条`" show-size-changer
        show-quick-jumper :pageSize.sync="page.pageSize" @change="handleNumChange" @showSizeChange="handleSizeChange" />
    </div>
    <el-dialog :visible.sync="dialogVisible" width="400px" custom-class="custom-class">
      <template #title>
        <span v-if="tenantCooperationFlag" style="font-weight: 600; font-size: 16px"><i class="el-icon-success"
            style="color: #14CA64;margin-right: 9px;"></i>恭喜您，抢单成功！</span>
        <span v-else style="font-weight: 600; font-size: 16px"><i class="el-icon-warning"
            style="color: #e6a23c;margin-right: 9px;"></i>暂时不能抢单，请联系客服开通协同订阅</span>
      </template>
      <div v-if="tenantCooperationFlag" style="font-size: 14px; color: #1F2733; padding: 0 1.5em 0 2em;">
        是否立即报价，如不需要立即报价可稍后至客户询价单列表去进行报价操作！<br />请注意，抢单成功后若未进行报价则会减少商机推荐。</div>
      <div v-else style="text-align: center;">
        <div>请扫码添加客服，客服将协助完成相关操作！</div>
        <el-image :src="kefu" style="width: 160px; height: 160px;"></el-image>
      </div>
      <span v-if="tenantCooperationFlag" slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">稍后报价</el-button>
        <el-button size="mini" type="primary" @click="naviToQuotePrice">立即报价</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="dialogVisible = false">我知道了</el-button>
      </span>
    </el-dialog>
    <DetailDialog v-if="detailDialogVisible" :dialog-visible.sync="detailDialogVisible" :id="currentOpportunity.id"
      :type="currentOpportunity.businessType" :isEncryptable="!currentOpportunity.scrambleFlag">
      <template
        v-if="!(currentOpportunity.inquireTenantStatus === 'REJECTED' || (currentOpportunity.scrambleFlag && currentOpportunity.inquireTenantStatus === 'QUOTED') || (!currentOpportunity.remainingScrambleNum && !currentOpportunity.scrambleFlag))"
        #button>
        <el-button v-if="currentOpportunity.scrambleFlag" type="primary"
          @click.stop="() => handlePress(currentOpportunity)">报价</el-button>
        <el-button v-else-if="currentOpportunity.remainingScrambleNum" type="primary"
          @click.stop="() => handlePress(currentOpportunity)">抢单</el-button>
      </template>
    </DetailDialog>
  </div>
</template>

<script>
import XzTagGroup from '../../../components/xiaozi-ui/xz-tag-group';
import { inquirePricePageListByRecommend, inquirePriceScramble } from '@/api/inquiryForm.js';
import * as dayjs from 'dayjs';
import DetailDialog from '../customerInquiryForm/detail/detailDialog.vue';
import { companyDetail } from '@/api/company';
import { findAuthorization } from '@/utils/findAuthorization';

const UNAUTHED = '1';
const AUTHING = '2';
const AUTHED = '3';

const AUDIT_ING = '1';
// const AUDIT_PASSED = '2';
const AUDIT_FAILED = '3';
export default {
  name: 'BusinessOpportunities',
  components: {
    XzTagGroup,
    DetailDialog,
  },
  computed: {
    statusWithAll () {
      return [
        {
          label: '全部',
          value: '',
        },
        {
          label: '未满额',
          value: 0,
        },
        {
          label: '已满额',
          value: 1,
        },
      ];
    },
  },
  data () {
    return {
      form: {
        fullFlag: '',
        deliveryProvinceNameOrCityName: '',
      },
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [],
      total: 100,
      sortFlag: 'DESC',
      opportunities: [],
      v1Ziguanyi: require('@/assets/shangji/v1_ziguanyi.svg'),
      jidan: require('@/assets/shangji/jidan.svg'),
      clock: require('@/assets/shangji/clock.svg'),
      location: require('@/assets/shangji/location.svg'),
      qiangdan: require('@/assets/shangji/qiangdan.svg'),
      baojia: require('@/assets/shangji/baojia.svg'),
      yimane: require('@/assets/shangji/yimane.svg'),
      qiangdanchenggong: require('@/assets/shangji/qiangdanchenggong.svg'),
      noImage: require('@/assets/shangji/no_img.svg'),
      dialogVisible: false,
      detailDialogVisible: false,
      tenantCooperationFlag: false,
      currentOpportunity: null,
      kefu: require('@/assets//kefu.jpg'),
    };
  },
  methods: {
    formatTime (v) {
      let ret = '';

      if (v) {
        ret = dayjs(v * 1).format('YYYY-MM-DD HH:mm');
      }

      return ret;
    },
    handleStatusChange (key, v) {
      this.form[key] = v.value;
      this.page.pageNum = 1;
      this.handleSearch();
    },
    handleSizeChange (_, size) {
      this.page.pageSize = size;
      this.handleSearch();
    },
    handleNumChange (num) {
      this.page.pageNum = num;
      this.handleSearch();
    },
    handleSort (type) {
      if (type === 'ASC') {
        this.sortFlag = this.sortFlag === 'ASC' ? '' : 'ASC';
      } else if (type === 'DESC') {
        this.sortFlag = this.sortFlag === 'DESC' ? '' : 'DESC';
      }

      this.handleSearch();
    },
    handleSearch () {
      inquirePricePageListByRecommend({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        fullFlag: this.form.fullFlag === '' ? undefined : !!this.form.fullFlag,
        deliveryProvinceNameOrCityName: this.form.deliveryProvinceNameOrCityName,
        orderBy: this.sortFlag ? [
          {
            orderByProperty: 'releaseTime',
            orderByType: this.sortFlag,
          },
        ] : [],
      }).then(({ body }) => {
        this.tableData = body.list || [];
        this.total = body.total ? body.total * 1 : 0;
      });
    },
    handleView (v) {
      this.currentOpportunity = v;

      this.detailDialogVisible = true;
    },
    async handlePress (v) {
      this.tenantCooperationFlag = v.tenantCooperationFlag;

      if (v.scrambleFlag) {
        this.$router.push({
          name: 'CUSTOMER_INQUIRY_HANDLE',
          query: {
            id: v.id,
            type: v.businessType,
          },
        });

        this.detailDialogVisible = false;
      } else if (v.remainingScrambleNum) {
        let userInfo = JSON.parse(this.$local.get('userInfo'));
        const { body } = await companyDetail({ id: userInfo.tenantId });

        if (v.releaseSource === 1 && body.authenticationStatus !== AUTHED) {
          let text = '';
          let showCancelButton = false;
          let cancelButtonText = '';
          let confirmButtonText = '';
          if (!findAuthorization('homePage:certification')) {
            text = '请联系管理员进行企业认证，认证审核通过后才能进行抢单。';
            confirmButtonText = '我知道了';
          }

          if (findAuthorization('homePage:certification')) {
            if (body.authenticationStatus === UNAUTHED && !body.authenticationAuditStatus) {
              text = `请先完成小紫平台${body.isPersonal ? '个人' : '企业'}认证，认证审核通过后才能抢单`;
              showCancelButton = true;
              cancelButtonText = '暂不认证';
              confirmButtonText = '立即认证';
            } else if (body.authenticationStatus === UNAUTHED && body.authenticationAuditStatus === AUDIT_FAILED) {
              text = `小紫平台${body.isPersonal ? '个人' : '企业'}认证审核失败，请重新认证，认证审核通过后才能抢单`;
              showCancelButton = true;
              cancelButtonText = '暂不认证';
              confirmButtonText = '重新认证';
            } else if (body.authenticationAuditStatus === AUDIT_ING && body.authenticationStatus === AUTHING) {
              text = `小紫平台${body.isPersonal ? '个人' : '企业'}认证正在审核中，审核通过后才能抢单`;
              confirmButtonText = '我知道了';
            }
          }

          const h = this.$createElement;
          this.$msgbox({
            title: '',
            showClose: true,
            showCancelButton: showCancelButton,
            cancelButtonText: cancelButtonText,
            confirmButtonText: confirmButtonText,
            message: h('div', { style: 'padding: 0 20px;' }, [
              h('i', { class: 'el-icon-error', style: 'color:#FF7A75; font-size: 17px;margin-right: 10px' }),
              h('span', { style: 'font-size: 16px; font-weight: bold; color: #1F2733;' }, '抢单失败'),
              h('p', { style: 'padding: 5px 10px 5px 30px; font-weight: 400; font-size: 14px; color: #1F2733;' }, text),
            ]),
          }).then(() => {
            if (['立即认证', '重新认证'].includes(confirmButtonText)) {
              this.$router.push({
                name: 'IDENTIFICATION',
              });
            }
          });
          return;
        }

        if (this.tenantCooperationFlag) {
          this.currentOpportunity = v;
          inquirePriceScramble({ id: v.id }).then(() => {
            this.handleSearch();
            this.detailDialogVisible = false;
            this.dialogVisible = true;
          });
        } else {
          this.detailDialogVisible = false;
          this.dialogVisible = true;
        }
      }
    },
    naviToQuotePrice () {
      this.$router.push({
        name: 'CUSTOMER_INQUIRY_HANDLE',
        query: {
          id: this.currentOpportunity.id,
          type: this.currentOpportunity.businessType,
        },
      });

      this.dialogVisible = false;
      this.detailDialogVisible = false;
    },
  },
  created () {
    this.handleSearch();
  },
};
</script>
<style scoped lang="scss">
.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 8px;

    .h-full {
      height: 100%;
      display: flex;
      flex-direction: column;

      .c_group {
        position: relative;
        background-color: #fff;
        border-radius: 4px;

        .title {
          font-size: 14px;
          font-weight: 600;
          padding-right: 2em;
        }

        .c_query {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;

          .c_time {
            position: relative;
            font-size: 14px;
            padding: 4px 26px 4px 10px;
            background: #FFFFFF rgba(0, 145, 255, 0.05);
            border-radius: 4px;
            color: #0091FF;
            border: 1px solid #0091FF;
            font-weight: 500;

            .sort-caret {
              width: 0;
              height: 0;
              border: 5px solid transparent;
              right: 7px;
              position: absolute;
              cursor: pointer;

              &.ascending {
                border-bottom-color: #c0c4cc;
                top: 0;

                &.active {
                  border-bottom-color: #0091FF;
                }
              }

              &.descending {
                border-top-color: #c0c4cc;
                bottom: 0;

                &.active {
                  border-top-color: #0091FF;
                }
              }
            }
          }

          .el-input {
            flex: 1;
            margin-left: 16px;
          }
        }
      }

      .table-wrapper {
        flex: 1;
        overflow: auto;

        .card-wrapper {
          display: inline-block;
          padding: 8px 8px 0 0;
          width: 25%;
          font-size: 14px;

          @media screen and (max-width: 1554px) {
            font-size: 12px;
          }

          .inner_card {
            background-color: #fff;
            border-radius: 6px;
            padding: 0 0 10px;

            .card_title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 66px;
              padding: 0 10px;

              &.urgent {
                background: linear-gradient(15deg, rgba(255, 255, 255, 0) 0%, rgba(246, 0, 0, 0.1) 100%);
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
              }

              .title_text {
                display: flex;
                align-items: center;
              }
            }

            .line {
              height: 1px;
              background-color: #EBEDF0;
              margin: 0 10px;
            }

            .forbid {
              opacity: 0.6;
            }

            .content1 {
              font-weight: 600;
              color: #1F2733;
              margin: 8px 0;
              height: 20px;
              line-height: 20px;
              padding: 0 10px;
            }

            .content2 {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              padding: 0 10px;

              .content2-text {
                flex: 1;
                background-color: #F5F7FA;
                color: #1F2733;
                height: 80px;
                margin-left: 8px;
                padding: 6px 7px;
                border-radius: 4px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .content3 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 10px;

              .content3-text {
                position: relative;
                padding-left: 19px;

                .el-image {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-40%);
                }
              }

              .text {
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .content3-text--sup {
                font-size: 10px;
                color: #237FFA;

                @media screen and (max-width: 1554px) {
                  font-size: 8px;
                }
              }
            }

            .release_time {
              font-size: 12px;
              color: #929AA6;
              padding-top: 7px;
            }
          }
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  /deep/ .custom-class {
    box-shadow: 0px 12px 24px 8px rgba(31, 39, 51, 0.1);
    border-radius: 6px;

    .el-dialog__header {
      border-bottom: none;
    }

    .el-dialog__footer {
      border-top: none;
    }
  }
}
</style>
