<template>
    <div class="log-wrapper">
      <a-table ref="logTableRef" :scroll="{ x: '100%', y: 540 }" :components="components" :data-source="tableData" :columns="columns" :pagination="false"
        :rowKey="'id'">
      </a-table>
    </div>
  </template>

<script>
import * as Moment from 'dayjs';
import { dynamicInformationPageList } from '@/api/inquiryForm.js';
import DraggableResizeMixin from '@/mixins/draggableResizeMixin.js';

export default {
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
  },
  mixins: [DraggableResizeMixin],
  computed: {
    columns () {
      let ret = [
        {
          title: '时间',
          dataIndex: 'createTime',
          width: '20%',
          key: 'createTime',
          ellipsis: true,
          customRender: (text) => {
            return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '/';
          },
        },
        {
          title: '操作人',
          dataIndex: 'createUserName',
          width: '15%',
          key: 'createUserName',
          ellipsis: true,
        },
        {
          title: '事项类型',
          dataIndex: 'actionDesc',
          width: '15%',
          key: 'actionDesc',
          ellipsis: true,
        },
        {
          title: '事项描述',
          dataIndex: 'content',
          minWidth: '50%',
          key: 'content',
          ellipsis: true,
        },
      ];

      return ret;
    },
  },
  data () {
    return {
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    init () {
      this.tableData = [];
      this.pageNum = 1;

      this.loadData();
    },
    async loadData () {
      const { body } = await dynamicInformationPageList({
        module: 'INQUIRE_PRICE',
        businessId: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderBy: [
          {
            orderByProperty: 'lastUpdateTime',
            orderByType: 'DESC',
          },
        ],
      });

      this.tableData = [...this.tableData, ...(body?.list || [])];

      this.total = body.total;
    },
    tableScroll () {
      let target = this.$refs.logTableRef.$el.querySelector('.ant-table-body');

      const scrollHeight = target.scrollHeight - target.scrollTop;
      const clientHeight = target.clientHeight;
      if (scrollHeight === 0 && clientHeight === 0) {
        this.pageNum = 1;
      } else {
        if (scrollHeight < clientHeight + 5) {
          if (this.tableData.length < this.total) {
            this.pageNum += 1;

            this.loadData();
          }
        }
      }
    },
  },
  mounted () {
    this.init();

    this.$refs.logTableRef.$el
      .querySelector('.ant-table-body')
      .addEventListener('scroll', this.tableScroll, {
        passive: true,
      });
  },
  beforeDestroy () {
    this.$refs.logTableRef.$el
      .querySelector('.ant-table-body')
      .removeEventListener('scroll', this.tableScroll);
  },
};
</script>
  <style lang="scss" scoped>
  .log-wrapper {
    background-color: #fff;
    padding: 8px;

    /deep/ .ant-table-thead {
      &>tr>th {
        padding: 9px 16px;
        font-weight: bold;
        background-color: #F2F4F7;
      }
    }

    /deep/ .ant-table-tbody {
      tr {
        background-color: #fff;

        &>td {
          padding: 9px 16px;
        }
      }
    }
  }
  </style>
