<template>
  <el-tabs v-model="activeName" style="margin-top: 8px">
    <el-tab-pane v-if="sendRepairListPermission && type === 'SEND_REPAIR'" label="寄修信息" name="sendRepair">
      <SendRepairTable :inquirePriceCode="inquirePriceCode" :isHandle="isHandle"></SendRepairTable>
    </el-tab-pane>
    <el-tab-pane v-if="sendRepairSpareListPermission && type === 'SEND_REPAIR'" label="备用机信息" name="backup">
      <BackupEquipmentTable :inquirePriceCode="inquirePriceCode" :inquirePriceId="inquirePriceId" :isHandle="isHandle"></BackupEquipmentTable>
    </el-tab-pane>
    <el-tab-pane v-if="workOrderListPermission" label="工单信息" name="workOrder">
      <WorkOrder :sourceNo="inquirePriceCode" :isHandle="isHandle"></WorkOrder>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import SendRepairTable from './SendRepairTable.vue';
import BackupEquipmentTable from './BackupEquipmentTable.vue';
import WorkOrder from './WorkOrder.vue';
import mixin from '../../../mixin';

export default {
  mixins: [mixin],
  props: {
    inquirePriceCode: {
      type: String,
      default: '',
    },
    isHandle: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    inquirePriceId: {
      type: [String, Number],
      default: '',
    },
  },
  components: {
    SendRepairTable,
    BackupEquipmentTable,
    WorkOrder,
  },
  watch: {
    type () {
      this.activeName = this.type !== 'SEND_REPAIR' ? 'workOrder' : this.sendRepairListPermission ? 'sendRepair' : this.sendRepairSpareListPermission ? 'backup' : 'workOrder';
    },
  },
  data () {
    return {
      activeName: 'sendRepair',
    };
  },
  mounted () {
    this.activeName = this.type !== 'SEND_REPAIR' ? 'workOrder' : this.sendRepairListPermission ? 'sendRepair' : this.sendRepairSpareListPermission ? 'backup' : 'workOrder';
  },
};
</script>
