<template>
    <div class="h-100">
      <table-with-pagination
        :tableData="orderList"
        ref="orderTable"
        class="content_block"
        :checkbox-config="{ reserve: true }"
        id="orderTable"
        columns-custom
        :columnList="columnList"
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @current-change="getOrderTableList"
        @sort-change="getOrderTableList"
      />
      <el-dialog
        title="导出工单"
        :visible.sync="printDialogVisible"
        @close="handlePrintDialogClosed"
        width="500px">
        <el-form ref="printTemplateForm"
          :model="{selectedPrintTemplate:selectedPrintTemplate}"
          label-width="100px">
          <el-form-item label="选择模板" prop="selectedPrintTemplate" :rules="{
            required:true,
            message:'请选择打印模板'
          }" >
            <el-select v-model="selectedPrintTemplate"
              value-key="id">
              <el-option v-for="i in printTemplateList"
                :key="i.id"
                :label="i.name"
                :value="i">
                <div class="d-flex justify-content-between align-items-center" style="max-width:400px">
                  <span class="flex-fill  text-ellipsis">
                    {{i.name}}
                  </span>
                  <span>
                    <el-tag size="mini" effect="plain" type="primary">{{i.paperSize}}</el-tag>
                    <el-tag size="mini" effect="plain" class="ml-1" type="primary">{{i.paperDirection === 'TRANSVERSE'?'横向':'纵向'}}</el-tag>
                  </span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="printDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handlePrint">导出</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
<script>
import * as dayjs from 'dayjs';
import OrderApi from '@/api/order';
import OrderStatusEnum from '@/enums/OrderStatusEnum';
import OrderSourceEnum from '@/enums/OrderSourceEnum';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { getCustomQueryConditions, getCustomQueryColumn, getCommonQueryPageList } from '@/api/commonForm';
import { queryLayoutByNodeApi, getPrintTemplateList, printFormTemplate, exportFormTemplate } from '@/api/formSetting';
import previewImage from '@/components/imagePreviewer/previewImage';
import _ from 'lodash';
import { getDefaultValueByTag, getDisplayValue, flatternFields, getSystemComponentFixedFields } from '@/components/form-generator/utils/component.js';
import downloadFile, { downloadAndZip } from '@/utils/download';
import mixin from '../../../mixin';

export default {
  name: 'ORDER_INDEX',
  mixins: [TablePaginationMixin, mixin],
  inject: {
    closeDialog: {
      default: undefined,
    },
  },
  data () {
    return {
      orderList: [],
      OrderTypeList: [],
      columnList: [],
      query: {
        type: null,
      },
      queryFormData: {},
      queryForm: {},
      currentOrderItem: null,
      printDialogVisible: false,
      printTemplateList: [],
      selectedPrintTemplate: null,
      exportType: 'Excel',
    };
  },
  created () {
    // 首次创建或者其他页面跳转过来携带参数，筛选条件重置
    // eg 工作台工单代办跳转而来
    const newParams = this.$route.params;
    const hasParams = newParams && Object.keys(newParams).length > 0;
    const hasCreated = !!this.hasCreated;

    if (hasParams || !hasCreated) {
      this.hasCreated = true;
      this.query.type = null;
      this.getCustomQueryConditionsHandle().then(()=>{
        this.queryForm = Object.assign(this.queryForm, {...newParams});
        this.handleSearch();
      });
    }
  },
  methods: {
    handlePrintDialogClosed () {
      this.$refs.printTemplateForm.resetFields();
      this.selectedPrintTemplate = null;
      this.exportType = 'Excel';
    },
    handleSearchModel () {
      this.getCustomQueryConditionsHandle().then(()=>{
        this.handleSearch();
      });
    },
    normalLizeConfig (schema) {
      schema.disabled = false;
      const config = schema.__config__;
      if (config.hasOwnProperty('filterParam')) {
        config.filterParam = null;
      }
      if (config?.tag === 'span') {
        schema.__config__.tag = 'el-input';
      } else if (config?.tag === 'el-date-picker' || config?.tag === 'el-time-picker') {
        schema = {
          __config__: config,
          style: {
            width: '100%',
          },
          type: 'daterange',
          defaultTime: ['00:00:00', '23:59:59'],
          valueFormat: 'timestamp',
          'range-separator': '至',
          'start-placeholder': '开始日期',
          'end-placeholder': '结束日期',
        };
      }
      schema.placeholder = schema.placeholder || `${schema.__config__.tag === 'el-input' ? '请输入' : '请选择'}${schema.__config__.label}`;
      return {
        ...schema,
        on: {
          input: (val)=>{
            this.$set(this.queryForm, config.renderKey, val);
          },
        }};
    },
    getFormCategoryList () {
      OrderApi.getFormTypeListByConfigId({ customizeSystemConfigCode: 'WORK_ORDER' }).then(rs=>{
        this.OrderTypeList = rs.body?.map(i => ({
          ...i,
          templateId: i.id,
        }));
      });
    },
    getCustomQueryConditionsHandle () {
      this.queryForm = {};
      return getCustomQueryConditions({ customizeSystemConfigCode: 'WORK_ORDER', customizeBusinessTypeCode: this.query.type }).then(
        ({ body }) => {
          this.queryFormData = _.cloneDeep(body) || [];
          this.queryFormData.forEach(column => {
            column.tag = column.columnType;
            this.$set(this.queryForm, column.columnKey, getDefaultValueByTag(column.columnValue));
          });
          return Promise.resolve();
        },
      );
    },
    getPrintViewModelAndFields () {
      return OrderApi.detail(this.currentOrderItem?.id).then(res=>{
        return queryLayoutByNodeApi({
          customizeBusinessTypeId: res.body?.typeMsg?.id,
          status: res.body?.statusMsg.value,
          evalStatus: res.body?.evalStatusMsg.value,
        }).then(model=>{
          let fieldMap = {};
          const fields = model.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc?.fields || {};
          flatternFields(fields, fieldMap);
          const formatViewModel = async (fields, modelValue, parentField, fieldList = [])=>{
            let viewModel = {};
            for (const field of fields) {
              const key = field?.__config__?.renderKey;
              if (field?.__config__?.layout === 'tableColumnFormItem') {
                const childrenFields = field.__config__.children[0]?.fields || [];
                viewModel[key] = await Promise.all((modelValue[key] || []).map((t)=>{
                  return formatViewModel(childrenFields, t, field, fieldList);
                }));
              } else if (field?.__config__?.layout === 'childFormItem') {
                if (modelValue && modelValue[field.relatedFormQueryBy]) {
                  const childFormFields = await getCustomQueryColumn({
                    customizeSystemConfigCode: field?.relatedFormType,
                    customizeBusinessTypeCode: field?.relatedFormTemplateCode,
                  }).then(res=>{
                    return (res.body || []).map((col)=>{
                      if (col.columnValue) {
                        return col.columnValue;
                      } else {
                        return {
                          __config__: {
                            renderKey: col.columnKey,
                            tag: col.columnType,
                          },
                        };
                      }
                    });
                  });
                  let params = {
                    systemConfigCodeFlag: field.relatedFormType,
                    type: field.relatedFormTemplateCode,
                  };
                  params[field.relatedFormFillField] = modelValue[field.relatedFormQueryBy];
                  let childFormDataList = await getCommonQueryPageList(params).then(data=>{
                    return data.body?.list || [];
                  });

                  viewModel[key] = await Promise.all(childFormDataList.map((t)=>{
                    // 自定列表的column包含在数据中，真正的字段类型需要做次合并
                    const fieldWithRealType = childFormFields.map(c=>{
                      const columnKeyInfo = t.columnKeyInfo || {};
                      return Object.assign({}, c, columnKeyInfo[c.__config__.renderKey]);
                    });
                    return formatViewModel(fieldWithRealType, t, field, fieldList);
                  }));
                }
              } else if (field?.__config__?.tag === 'c-work-hour' || field?.__config__?.tag === 'c-spare-part-list') {
                const fields = getSystemComponentFixedFields(field);
                viewModel[key] = await Promise.all((modelValue[key] || []).map(t=>{
                  return formatViewModel(fields, t, field, fieldList);
                }));
              } else {
                try {
                  viewModel[key] = getDisplayValue(field, modelValue) || null;
                } catch {
                  viewModel[key] = modelValue[key];
                }
                let cloneField = _.cloneDeep(field);
                cloneField.__config__.renderKey = (parentField?.__config__.renderKey ? (parentField?.__config__.renderKey + '.') : '') + cloneField.__config__.renderKey;
                fieldList.push(cloneField);
              }
            }
            return viewModel;
          };
          let flatternedFields = [];
          return formatViewModel(Object.keys(fieldMap).map(key=>fieldMap[key]), res.body || {}, null, flatternedFields).then(viewModel=>{
            return {viewModel, fields: flatternedFields};
          });
        });
      });
    },
    handlePrint () {
      this.$refs.printTemplateForm.validate(valid=>{
        if (valid) {
          this.getPrintViewModelAndFields().then(({viewModel, fields})=>{
            this.printDialogVisible = false;
            let request = null;
            let contentType = null;
            if (this.exportType === 'PDF') {
              request = printFormTemplate;
              contentType = 'application/pdf';
            } else if (this.exportType === 'Excel') {
              request = exportFormTemplate;
              contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            }
            request && request({
              id: this.selectedPrintTemplate.id,
              detail: viewModel,
              list: fields,
            }).then(({ data, headers }) => {
              const fileName = headers['content-disposition'].split('filename=')[1] ? decodeURIComponent(headers['content-disposition'].split('filename=')[1]) : `${this.currentOrderItem.code}.pdf`;
              downloadFile({
                data,
                type: contentType,
                fileTitle: fileName,
              });
              this.$message.success('导出成功');
            });
          });
        }
      });
    },
    handlePrintKeduTemplate (order) {
      OrderApi.detailList(order.id).then(({ data, headers }) => {
        let fileName = headers['content-disposition'].split('filename=')[1] || `${order.code}.pdf`;
        if (order.orderSource === 'GXT_INQUIRE_PRICE') { // 来自派工
          fileName = `资管易_维修记录单-${order.code}.pdf`;
        }
        downloadFile({
          data,
          type: 'application/pdf',
          fileTitle: fileName,
        });
        this.$message.success('导出成功');
      });
    },
    handleSearch () {
      this.getFormCategoryList();
      this.pagination.currentPage = 1;
      this.orderList.splice(0, this.orderList.length);
      this.getOrderTableList();
    },
    getOrderTableList () {
      getCustomQueryColumn({
        customizeSystemConfigCode: 'WORK_ORDER',
        customizeBusinessTypeCode: this.query.type,
      }).then(({ body }) => {
        this.columnList = [];
          body?.forEach(colm => {
            this.columnList.push({
              tooltip: true,
              label: colm.columnName,
              minWidth: '160px',
              prop: colm.columnKey,
              fixed: colm.columnKey === 'code' ? 'left' : '',
              render: (h, { row }) => {
                if (colm.columnKey === 'code') {
                  return (
                    <span className={{workOrderDetailPermission: 'text-primary cursor-pointer'}} onClick={this.handleActionClick.bind(this, row, 'detail')}>
                      {row.code}
                    </span>
                  );
                } else if (colm.columnType === 'upload') {
                  // 特殊处理文件预览
                  const fileList = (row[colm.columnKey] || []).map(t=>({url: t.url, type: t.type}));
                  if (!fileList[0]) {
                    return '--';
                  }
                  return <div>
                    <el-link type="primary" onClick={()=>previewImage({fileList})}>预览</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="primary" onClick={()=>downloadAndZip(fileList, row.code).then(()=>this.$message.success('下载成功'))}>下载</el-link>
                  </div>;
                } else {
                  const columnKeyInfo = row.columnKeyInfo || {};
                  const columnSchema = {
                    ...columnKeyInfo[colm.columnKey],
                    __config__: {
                      tag: colm.columnType,
                      renderKey: colm.columnKey,
                    },
                  };
                  return getDisplayValue(columnSchema, row);
                }
              },
            });
          });
          if (this.isHandle) {
            this.columnList.push({
              prop: 'action',
              exportable: false,
              label: '操作',
              minWidth: '180px',
              fixed: 'right',
              render: (h, { row }) => {
                let actions = [];
                if (
                  row.orderSource === OrderSourceEnum.系统内部
                && (row.status === OrderStatusEnum.已取消 || row.status === OrderStatusEnum.待指派)
                ) {
                  actions.push(
                    <span
                      v-auth={'workOrder:delete'}
                      class="cursor-pointer text-primary ml-2 "
                      onClick={this.handleActionClick.bind(this, row, 'delete')}
                    >
                    删除
                    </span>,
                  );
                }
                if (this.workOrderPrintPermission) {
                  actions.push(
                    <span
                      v-auth={'workOrder:print'}
                      class="cursor-pointer text-primary ml-2 "
                      onClick={this.handleActionClick.bind(this, row, 'print')}
                    >
                    打印工单
                    </span>,
                  );
                }
                return <div>{...actions}</div>;
              },
            });
          }
          const actionMethods = 'getProductList';
          OrderApi[actionMethods]({
            ...this.pagination,
            orderSource: 'GXT_INQUIRE_PRICE',
            sourceNo: this.sourceNo,
            pageNum: this.pagination.currentPage,
          }).then(res => {
            this.orderList = res.body?.records || [];
            this.pagination.total = res.body?.total || 0;
          });
      });
    },
    handleActionClick (item, action) {
      this.currentOrderItem = item;
      if (action === 'delete') {
        this.$confirm(`确定删除工单${item.code}吗?`, '删除工单').then(() => {
          OrderApi.deleteOrders({ code: item.code, id: item.id }).then(() => {
            this.getOrderTableList();
          });
        });
      } else if (action === 'detail') {
        if (this.workOrderDetailPermission) {
          this.closeDialog && this.closeDialog();

          this.$router.push({
            name: 'ORDER_EDIT',
            query: {
              id: item.id,
              templateId: item?.typeMsg?.id,
            },
          });
        }
      } else if (action === 'print') {
        // 柯渡工单继续使用后端导出模板
        // 通过模板是否是定制模板来区分，由于后端缺少对应字段信息 故增加固定code兼容判断
        if (item.typeMsg?.prefabricateType === 'USER_PREFABRICATE' || item.typeMsg?.code === 'REPAIR_WORK_ORDER' || item.typeMsg?.code === 'MAINTENANCE_WORK_ORDER') {
          this.handlePrintKeduTemplate(item);
        } else {
          getPrintTemplateList({
            customizeBusinessTypeCode: item.typeMsg?.code,
            tenantId: item.tenantId || undefined,
          }).then(({body})=>{
            this.printTemplateList = body || [];
            if (this.printTemplateList.length === 0) {
              this.$message.warning('暂无打印模板，请联系管理员');
            } else {
              this.printDialogVisible = true;
            }
          });
        }
      }
    },
    resetTable (refresh = true) {
      refresh && this.getOrderTableList();
    },
  },
  props: {
    isHandle: {
      type: Boolean,
      default: false,
    },
    sourceNo: {
      type: String,
      required: true,
    },
  },
  filters: {
    dateFormatFilter (val) {
      return dayjs(val).format('YYYY-MM-DD');
    },
    timeFormatFilter (val) {
      return dayjs(val).format('HH:mm');
    },
    emptyReplace (val) {
      return val || '--';
    },
  },
};
</script>
